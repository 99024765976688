.faq-container {
  @media #{$breakpoint-above-tablet} {
    padding: 0 7vw;
    position: sticky;

    .faq-row {
      margin-right: 0 !important;
      flex-wrap: nowrap !important;

      & .faq-left-nav {
        .clearAllFilterButton {
          width: 101px;
          border-radius: 200px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 15px;
          background: $Brand-Black;
          color: $white;
          outline: none;
          border: 1px solid $Light-Gray;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          cursor: pointer;
          margin-top: 11px;
        }

        & .Line {
          margin-top: 61px;
          margin-bottom: 30px;
          width: 280px;
          margin-left: 0px;
        }

        & img.Messageimage {
          margin-right: 10px;
          margin-bottom: 2px;
        }

        & img.phoneIcon {
          margin-right: 10px;
          margin-left: -17px;
        }

        & button.button.whiteButton.blackBorder.Contact-button {
          width: 242px;
          height: 44px;
          margin-bottom: 20px;
          display: block;
          //font-family: Helvetica Neue;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 24px;
          padding-top: 10px;
          padding-bottom: 10px;

          &:hover {
            img {
              content: url('../../staticAssets/images/Call-white.svg');
            }
          }
        }
        & .faq-in {
          padding-left: 40px;
          padding-right: 40px;
        }
        & button.button.whiteButton.blackBorder.Message-button {
          width: 242px;
          height: 44px;
          //font-family: Helvetica Neue;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 24px;
          padding-top: 10px;
          padding-bottom: 10px;
          margin-bottom: 35px;

          &:hover {
            img {
              content: url('../../staticAssets/images/Mail_Icon_White.svg');
            }
          }
        }

        & .faq-title {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 38px;
          line-height: 43px;
          letter-spacing: 1px;
          text-transform: $uppercase;
          padding-bottom: 1vw;
        }

        & .outer-ul {
          padding-left: 0;
          list-style: none;
          justify-content: 'space-around';

          & li {
            padding-top: 19px;

            & a {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.1px;
              color: $Dark-Gray;
              text-decoration: none;

              &:hover {
                text-decoration: none;
              }
            }

            & a.active {
              font-weight: bold;
              letter-spacing: normal;
              background-color: transparent;
              color: $Brand-Black;
              text-decoration: none;
              font-size: 1.125rem;
            }

            & a.active::after {
              display: none;
            }
          }

          & .inner-ul {
            list-style: none !important;
            justify-content: 'space-around';
            padding-left: 1vw;

            &.nested {
              display: none;
            }

            .nested {
              display: none;
            }

            .caret-down::before {
              content: url('../../staticAssets/images/tree-minus.svg');
              display: inline-block;
              margin-right: 6px;
            }

            &.active-tree {
              display: block;
            }

            .active-tree {
              display: block;
            }

            & li {
              & a {
                font-size: 14px;

                &:hover {
                  text-decoration: none;
                }
              }

              a.caret {
                &:hover {
                  text-decoration: none;
                }
              }

              & a.active {
                font-weight: bold;
              }

              & a.active::after {
                display: none;
              }

              & a.active::before {
                content: '\2212';
                color: red;
                font-size: 16px;
                padding-right: 0.5vw;
              }
            }
          }
        }

        .anchorTags {
          text-decoration: none;
        }
      }

      & .faq-right-nav {
        margin-left: 0;
        margin-top: 4.5%;

        & .SearchBar {
          padding-bottom: 17px;
          border-bottom: 2px solid #202124;
          display: flex;
          justify-content: flex-end;
          margin-right: 100px;

          & .faq-search-menu {
            // display: flex;
            // justify-content: flex-end;

            & ::placeholder {
              /* Firefox */
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 600;
              color: $Brand-Black;
              text-align: left;
              font-size: 12px;
              line-height: 20px;
              opacity: 1;
            }

            & :-ms-input-placeholder {
              /* Internet Explorer 10-11 & Edge */
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 600;
              color: $Brand-Black;
            }

            & .faq-search-menu-input {
              border-radius: 34px;
              border: 1px solid $Light-Gray;
              padding-left: 41px;
              padding-right: 35px;
              padding-bottom: 2px;
              height: 36px;
              width: 320px;
              text-align: left;
              margin-bottom: 3%;
              font-weight: 600;
              font-size: 12px;
              line-height: 22px;
            }

            & .faq-search-icon {
              position: absolute;
              right: 25.1rem;
              top: 5px;
              //right: 14vw;
            }

            // FireFox
            @-moz-document url-prefix() {
              & .faq-search-menu-input {
                padding: 8px 35px 7px 41px;
                height: 36px;
                width: 320px;
                text-align: left;
                margin-bottom: 3%;
              }
            }

            & .searchgrey-icon-img {
              position: relative;
              bottom: 2px;
              padding: 2px 10px;
              padding-left: 0;
              opacity: 0.4;
            }

            & .faq-input-dark-cross {
              position: absolute;
              right: 130px;
              top: 10px;
              cursor: pointer;
            }
          }
        }

        .totalNumbers {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: #202124;
          margin-top: 60px;
          margin-bottom: 40px;
          margin-right: 100px;

          .enteredSearch {
            color: #e4002b;
          }
        }

        .searchgrey-icon-img {
          position: relative;
          bottom: 2px;
          padding: 2px 10px;
          padding-left: 0;
          opacity: 0.4;
        }

        .inputAutoComplete {
          position: absolute;
          margin-top: 0;
          width: 280px;
          // height: max-content;
          background: $Brand-Black;
          color: $white;
          z-index: 1;
          right: 0;
          height: 333px;
          overflow-x: scroll;
          margin-right: 115px;
        }

        .autoCompleteList {
          list-style: none;
          color: $white;
          padding: 0;
          margin: 0;
          cursor: pointer;
          padding-top: 4%;
          padding-bottom: 22px;

          li:hover {
            background-color: #494949;
          }
        }

        .wrapperAutoComplete {
          width: 100%;
          position: relative;
          padding: 2% 0 2% 0;
          display: flex;
          align-items: center;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 26px;
          padding-inline-start: 20px;
          padding-inline-end: 10px;
        }

        .SuggestionHeader {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          color: #fff;
          padding-top: 24px;
          padding-left: 20px;
          letter-spacing: 0.3px;
        }

        & .Accordian {
          margin-bottom: 120px;

          & .AccordianTitle {
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: #202124;
            margin-top: 100px;
            margin-bottom: 40px;
          }

          #lastElement {
            border-bottom: 2px solid $Light-Gray;
          }

          .ourAds-accordian {
            padding-top: 39px;
            padding-bottom: 38px;
            border-top: 2px solid $Light-Gray;
            margin-right: 100px;

            .global-accordian-header-container {
              padding-left: 30px;
              padding-right: 44px;

              .global-accordian-header {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: $Brand-Black;
              }

              .global-accordian-arrow {
                width: 10px;
                height: 10px;
              }
            }

            .global-accordian-content {
              margin-top: 50px;
              font-family: $National2Regular;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: -0.1px;
              color: $Dark-Gray;
              padding-left: 30px;
              padding-right: 70px;

              .ourAds-accordian-answer > p > b {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 30px;
                color: $Brand-Black;
              }

              .ourAds-accordian-answer > ul {
                padding-left: 14px;
              }
            }
          }

          .ourAds-accordian-open {
            background-color: $Mashed-Potato-Gray;
          }
        }
      }
    }
  }

  #FirstHeading {
    margin-top: 60px;
  }
}

.faq-row {
  margin-right: 0 !important;
  flex-wrap: nowrap !important;

  & .faq-left-nav {
    height: fit-content;
    width: 18vw;
    position: sticky;
    margin-left: 2.8%;
    top: 168px;
  }

  & .faq-right-nav {
    margin-left: 0;
    margin-top: 4.5%;
  }
}

.faq-stripes-section {
  width: 100%;
  top: 0;
  background-color: transparent;
  z-index: 111;
  margin-bottom: 7%;
}

// MOBILE SCSS**********************************************************************************************
.Mob-faq-container {
  @media #{$breakpoint-below-tablet} {
    width: 96%;
    position: sticky;

    #FirstHeading {
      margin-top: 45px;
    }

    .totalNumbers {
      @media #{$breakpoint-below-tablet} {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #202124;
        margin-top: 29.7px;
        margin-bottom: 40px;
        margin-left: 20px;
        margin-right: 20px;
      }

      .enteredSearch {
        color: #e4002b;
      }
    }

    & .faq-nav {
      position: sticky;
      top: 0;
      width: 100%;
      padding-left: 15px;

      & .TopDiv {
        top: 0;
        & .Mob-faq-stripes-section {
          margin-left: 21px;
          margin-right: 0;

          & img.stripes-img {
            width: 12%;
          }
        }

        & .Mob-faq-title {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 36px;
          line-height: 36px;
          text-transform: uppercase;
          color: #202124;
          margin-left: 21px;
          margin-top: 17px;
        }

        & .Mob-faq-search-menu {
          display: flex;
          justify-content: center;
          margin-top: 30px;

          & ::placeholder {
            /* Firefox */
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 600;
            color: $Brand-Black;
            text-align: left;
            font-size: 12px;
            line-height: 22px;
            opacity: 1;
            padding-left: 0;
            padding-top: 7px;
            padding-bottom: 7px;
          }

          & :-ms-input-placeholder {
            /* Internet Explorer 10-11 & Edge */
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 600;
            color: $Brand-Black;
          }

          & .faq-search-menu-input {
            border-radius: 34px;
            border: 1px solid $Light-Gray;
            padding: 0;
            padding-top: 5px;
            padding-bottom: 7px;
            height: 36px;
            width: 100%;
            text-align: left;
            margin-bottom: 1.5%;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 22px;
            color: #202124;
            margin-left: 20px;
            margin-right: 20px;
            padding-right: 33px;
            padding-left: 38px;
          }

          & .Mob-faq-search-icon {
            // position: absolute;
            // padding-right: 279px;
            // padding-top: 4px;

            // FireFox
            img.search-icon-img {
              position: absolute;
              padding-left: 33px;
              padding-top: 10.15px;
            }
          }

          @-moz-document url-prefix() {
            & .faq-search-menu-input {
              padding: 7px 35px 7px 38px;
              height: 36px;
              width: 100%;
              margin-bottom: 1.5%;
            }
          }

          & .searchgrey-icon-img {
            position: relative;
            bottom: 2px;
            padding: 2px 10px;
            padding-left: 0;
            opacity: 0.4;
          }

          & .faq-input-dark-cross {
            position: absolute;
            margin-left: -44px;
            margin-top: 10px;
          }
        }
      }

      .ListItems {
        border-top: 1px solid #e3e3e3;
        border-bottom: 1px solid #e3e3e3;
        margin-top: 29px;
        // margin-bottom: 30px;
        height: 47px;
      }

      & .mob-outer-ul {
        padding-left: 0;
        display: flex;
        margin-top: 12px;
        overflow-x: auto;
        overflow-y: hidden;
        margin-bottom: 0;

        & li {
          display: flex;
          margin-left: 20px;
          height: 33px;

          & a {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $Dark-Gray;
            opacity: 0.5;
            display: inline-flex;
            width: max-content;
          }

          & a.active {
            font-weight: bold;
            letter-spacing: normal;
            background-color: transparent;
            color: $Brand-Black;
            text-decoration: none;
            border-bottom: 2px solid $Brand-Black;
            opacity: 1;
          }

          & a.active::after {
            display: none;
          }
        }
      }

      .Faq-mobile-questions {
        margin-bottom: 60px;

        .Mob-AccordianTitle {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: #202124;
          margin-top: 61px;
          margin-bottom: 40px;
          margin-left: 20px;
        }

        #lastElement {
          border-bottom: 2px solid $Light-Gray;
        }

        .ourAds-accordian {
          padding-top: 36px;
          padding-bottom: 36px;
          border-top: 2px solid $Light-Gray;
          padding-right: 20px;
          padding-left: 20px;

          .global-accordian-header-container {
            padding-left: 16px;
            .global-accordian-header {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              color: $Brand-Black;
            }
          }

          .global-accordian-content {
            margin-top: 24px;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.1px;
            color: $Dark-Gray;
            padding-left: 16px;

            .ourAds-accordian-answer > p > b {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 30px;
              color: $Brand-Black;
            }

            .ourAds-accordian-answer > ul {
              padding-left: 14px;
            }
          }
        }

        .ourAds-accordian-open {
          background-color: $Mashed-Potato-Gray;
        }
      }
    }

    .LastDiv {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 60px;

      .stillAQues {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #202124;
      }

      // span.ContactUsButtonMob {
      //   padding-left: 15px;
      // }
      hr.Line {
        margin-top: 20px;
        margin-bottom: 30px;
      }

      .phoneIconMob {
        margin-right: 12px;
        margin-left: -14px;

        @media screen and (max-width: 304px) {
          margin-right: 10px;
        }
      }

      .MessageimageMob {
        margin-right: 12px;
        margin-left: -14px;

        @media screen and (max-width: 304px) {
          margin-right: 10px;
        }
      }

      .whiteButton.blackBorder {
        margin-left: 0;
      }

      .ButtonDiv {
        display: flex;
        justify-content: space-between;

        span {
          width: 50%;

          &:first-child {
            margin-right: 15px;
          }
        }

        span > .ContactUsButtonMob {
          border: 1px soild red;
        }

        .button.button.whiteButton.blackBorder.Contact-button {
          width: 100%;
          height: 44px;
          font-family: $National2Regular;
          font-style: normal;
          font-size: 14px;
          line-height: 24px;
          padding-top: 10px;
          padding-bottom: 10px;

          &:hover {
            img {
              content: url('../../staticAssets/images/Call-white.svg');
            }
          }
        }

        .button.button.whiteButton.blackBorder.Message-button {
          width: 100%;
          height: 44px;
          font-family: $National2Regular;
          font-style: normal;
          font-size: 14px;
          line-height: 24px;
          padding-top: 10px;
          padding-bottom: 10px;
          padding-right: 15px;
          padding-left: 20px;

          &:hover {
            img {
              content: url('../../staticAssets/images/Mail_Icon_White.svg');
            }
          }
        }

        @media screen and (max-width: 385px) {
          .button.button.whiteButton.blackBorder.Contact-button,
          .button.button.whiteButton.blackBorder.Message-button {
            padding-right: 15px;
            padding-left: 23px;
          }
        }

        @media screen and (max-width: 304px) {
          .button.button.whiteButton.blackBorder.Contact-button,
          .button.button.whiteButton.blackBorder.Message-button {
            padding-right: 1px;
            padding-left: 18px;
          }
        }
      }
    }
  }
}

.mobileFilter {
  & .modal-content {
    @media #{$breakpoint-below-tablet} {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    & .modal-header {
      & .header-title {
        @media #{$breakpoint-below-tablet} {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          color: #494949;
          text-transform: capitalize;
        }
      }
    }

    & .modal-body {
      @media #{$breakpoint-below-tablet} {
        height: auto;
        background: #202124;
      }

      & .Mob-faq-search-menu {
        padding-left: 12px;

        & ::placeholder {
          /* Firefox */
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 600;
          color: $Brand-Black;
          text-align: left;
          font-size: 12px;
          line-height: 20px;
        }

        & :-ms-input-placeholder {
          /* Internet Explorer 10-11 & Edge */
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 600;
          color: $Brand-Black;
        }

        & .faq-search-menu-input {
          border-radius: 34px;
          border: 1px solid $Light-Gray;
          padding-left: 40px;
          padding-right: 35px;
          height: 36px;
          width: 335px;
          text-align: left;
          margin-bottom: 3%;
        }

        & .Mob-faq-search-icon {
          position: absolute;
          left: 25px;
          bottom: 16px;
          //right: 14vw;
        }

        & .searchgrey-icon-img {
          position: relative;
          bottom: 2px;
          padding: 2px 10px;
          padding-left: 0;
          opacity: 0.4;
        }

        & .faq-input-dark-cross {
          position: absolute;
          right: 0;
          bottom: 21px;
          left: 326px;
          cursor: pointer;
        }
      }

      .searchInputMobMenuPlp {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;

        .autoCompleteList {
          list-style: none;
          color: $white;
          padding: 0;
          margin: 0;
          cursor: pointer;
          width: auto;

          li:hover {
            background-color: #494949;
          }
        }

        .wrapperAutoComplete {
          width: 100%;
          position: relative;
          padding: 2% 0 0 0;
          display: flex;
          align-items: center;
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 26px;
          padding-inline-start: 1%;
          background-color: unset;
        }

        .SuggestionHeader {
          display: none;
        }

        .searchgrey-icon-img {
          position: relative;
          bottom: 2px;
          padding: 2px 10px;
          opacity: 0.4;
        }

        .search-menu-inputMob {
          border-radius: 34px;
          border: 1px solid $Dark-Gray;
          // padding-left: 48px; Sonar Issue
          padding: 8px 38px;
          width: 90vw;
          margin: auto;
          background-color: $Dark-Gray;
          color: $white;
          display: block;
        }

        .search-iconMob {
          position: relative;
          bottom: 33px;
          left: 22px;
          width: 0;
        }

        .input-dark-cross-mob {
          position: absolute;
          margin-top: -29px;
          left: 87%;
        }
      }
    }
  }
  // }
}

.faq-container-global {
  .faq-container .faq-row .faq-right-nav .inputAutoComplete .SuggestionHeader {
    padding-right: 20px;
  }
  .faq-container .faq-row .faq-right-nav .Accordian .ourAds-accordian {
    .global-accordian-content {
      .ourAds-accordian-answer {
        a {
          color: $black;
        }
        a :hover {
          color: $black;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.1px;
        }
      }
      @media #{$breakpoint-above-tablet} {
        margin-top: 26px;
      }
    }
    .global-accordian-arrow {
      @media #{$breakpoint-above-tablet} {
        width: 15px;
        height: 15px;
      }
    }
  }
  @media #{$mobile-only} {
    .Mob-faq-container .faq-nav .Faq-mobile-questions .ourAds-accordian {
      .global-accordian-content {
        .ourAds-accordian-answer {
          a {
            color: $black;
          }
          a :hover {
            color: $black;
          }
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.1px;
          }
        }
      }
      .global-accordian-arrow {
        width: 15px;
        height: 15px;
      }
    }
  }
  @media #{$breakpoint-ipad-12-pro-portrait} {
    .faq-left-nav {
      position: relative !important;
      top: 0 !important;
    }
  }
  .mobileFilter .modal-content .modal-body .searchInputMobMenuPlp .input-dark-cross-mob {
    @media #{$breakpoint-below-tablet} {
      left: 91%;
    }
    @media #{$mobile-only} {
      left: 87.5%;
    }
  }
  *,
  ::after,
  ::before {
    outline: none;
  }

  .faq-container .faq-row .faq-right-nav {
    margin-left: 0;
    @media #{$breakpoint-desktop-small} {
      margin-left: 4%;
    }
  }
  .faq-container {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 0;
    @media #{$breakpoint-desktop-small} {
      max-width: 995px;
    }
  }
  .faq-stripes-section .stripes-img {
    @media #{$breakpoint-above-destop-wide} {
      height: 1.5vw;
      width: 3vw;
      margin-left: 0.5vw;
    }
  }
  @media #{$mobile-only} {
    .Mob-faq-container .faq-nav .TopDiv .Mob-faq-stripes-section {
      margin-left: 5px;
    }
    .Mob-faq-container .faq-nav .TopDiv .Mob-faq-stripes-section img.stripes-img {
      width: 19.5% !important;
      height: 25px;
    }
  }
  @media #{$breakpoint-below-tablet} {
    .Mob-faq-container .faq-nav .TopDiv .Mob-faq-stripes-section {
      margin-left: 8px;
    }
    .Mob-faq-container .faq-nav .TopDiv .Mob-faq-stripes-section img.stripes-img {
      width: 9.5%;
      height: 25px;
    }
    .modal-body {
      @media #{$breakpoint-below-tablet} {
        .SuggestionHeader {
          display: inline !important;
        }
      }
    }
  }
  @media #{$breakpoint-above-tablet} {
    .faq-container .faq-row .faq-left-nav .Line {
      margin-top: 34px;
      margin-bottom: 94px;
    }
  }
}
